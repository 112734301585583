import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { EmptyComponent } from './empty/empty.component';
import { MobileComponent } from './mobile/mobile.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'login/:orgId',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'login/:orgId/:appId/:locId/:lang',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
    },
    {
        path: 'loginWithDialog',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    
    {
        path: 'reseller-signup',
        loadChildren: () => import('./reseller-signup/reseller-signup.module').then(m => m.ResellerSignupModule)
    },
    {
        path: 'mobile2',
        component: MobileComponent
    },
    { path: 'empty', component: EmptyComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {}
